<template>
    <div>  
  <the-table-page :titleTable="$t('Связанные объекты')"
                  :tableData="tableData"
                  :load="load"
                  fieldId="entityId"
                  :updateData="updateData"
                  :fieldInfoList="fieldInfoList"
                  :searchStart="searchStart"
                  :sortData="sortData"
                  :setSort="setSort"
                  :deleteEl="deleteEl"
                  :prefixEdit="`/user`"
                  :linkCreate="`/user/create`"
                  configName = "sublinksuser"
                  :noInfoDel="true"
                  :forceDelete="isEdit"
                  :forsePaginate=false
                  :forsePerpage=false
                  
                  :forceIsShow="false"
                  :forceIsEdit="false"
                  :forceIsCreate="false"

  ></the-table-page> 
  </div>
</template>

<script>
   
    import TheTablePage from "../../../TheTablePage.vue";
    import {GET_SUBJECT_LINK_USER} from "./store/type";

    import formatData from "../../../../helpers/converters/formatData"
    import {
        SET_MAIN_PAGINATION,
        SET_MAIN_PAGINATION_PER_PAGE,
        SET_MAIN_PAGINATION_PAGE,
        SET_MAIN_SORT_ENTITY,
        SET_MAIN_SEARCH_ENTITY
    } from "../../../../../src/store/type"

    import {mapState} from 'vuex';
    import {DEFAULT_PER_PAGE, TYPE_ERRORS, FIELD_LANG_CODE} from '../../../../constant'
    import notify from '../../../../helpers/notify/notify'
    import convertLangDataToTable from '../../../../helpers/clearReactive'
    import clearReactive from '../../../../helpers/clearReactive';

    export default {
        components: {
            TheTablePage
        },
        props: {
              delEl:{type: Function},
              newRole:{type: Boolean, default: false},
              newData: {type: Array},
              subjectIdList: {type: Array},
              isEdit:{type: Boolean, default: true},
        },
        data() {
            return {
               
                load: false,
                firstLoad: false
            }
        },
        computed: {
            ...mapState({
                fieldInfoList: state => state.users.fieldInfoListLink,
                lang: state => state.common.lang,
                sortData: state => state.pagination.sortData,
            }),
            tableData() {
                let hasLang = false
                let langNow = this.$store.state.common.lang
                let dataNow = clearReactive(this.$store.state.users.dataLink) || []
                dataNow = dataNow.filter( el=>{
                  let find =  this.subjectIdList.find(id=>id == el.entityId)
                  return find?true:false
                })
                if(this.newData && this.newData.length>0){
                    this.newData.forEach(el=>{
                        dataNow.push(el)
                    })
                }
                if (dataNow && dataNow.length > 0) {
                    dataNow = convertLangDataToTable(dataNow, langNow, FIELD_LANG_CODE, hasLang) 
                    return dataNow
                }
            }
        },
        watch: {
            lang() {
                this.initData()
            }
        },
        methods: {
            getData() {
                if(this.newRole && this.firstLoad){
                    return
                }
                this.firstLoad = true
                this.load = true
                this.$store.dispatch(GET_SUBJECT_LINK_USER, {
                    self: this,
                    id: this.$route.params.UserId,
                    UserId: this.$route.params.UserId
                }).then(res => {
                    this.load = false
                })
                    .catch(err => {
                        this.load = false
                        notify(this, this.$t('Ошибка получения данных'), TYPE_ERRORS.DANGER)
                    })
            },
            updateData() {
                this.getData()
            },
            searchStart(search) {
                this.$store.commit(SET_MAIN_SEARCH_ENTITY, search)
                this.getData()
            },
            setSort(val) {
                this.$store.commit(SET_MAIN_SORT_ENTITY, val)
                this.getData()
            },
            deleteEl(id) {
                this.delEl(id)
            },
            initData() {
                this.$store.commit(GET_SUBJECT_LINK_USER ,{ data: [], fieldInfoList: []})
                this.$store.commit(SET_MAIN_SORT_ENTITY, {})
                this.$store.commit(SET_MAIN_SEARCH_ENTITY, '')
                this.$store.commit(SET_MAIN_PAGINATION_PER_PAGE, DEFAULT_PER_PAGE)
                this.$store.commit(SET_MAIN_PAGINATION_PAGE, 1)
                this.getData()
            }
        },
        created() {
            this.initData()
        }
    }
</script>
