<template>
  <common-page-edit 
    :title="title"
    :nameRoleEntity="nameRoleEntity"
    :apiNameGet="apiNameGet"
    :apiNameCreate="apiNameCreate"
    :apiNameUpdate="apiNameUpdate"
    routeNameShow="UserShow"
    routeNameCreate="CreateUser"
    :keyRoute="'UserId'"
    :backUrl="pathBack"
    :convertLoadData="convertLoadData"
    :paramsSave="paramsSave"
    :isDefaultConverter="false"
    @set-loadData="setLoadData"
    @set-sheme="setSheme"
    :readDataFromParent="true"
    :loadDataIn="loadData"
    :schemeIn="scheme"
    :loadIn="load"
    :controlLoad="true"  
    @set-load="setLoad"
    :paramsSend="paramGet"
  > 
  
    <template v-slot:block2>
      <div class="row"> 
        <div class="col-12" v-if="loadData && isEdit">
          <EditLink
            :setNewLink="setNewLink"
            :newRole="newRole"
            :roleId="loadData.roleId ? loadData.roleId : 0"
            :isEdit="isEdit"
            :subjectIdList="loadData.subjectIdList"
          />
        </div>
        <div v-if="loadData" :class="'col-12'">
          <LinksSublinks
            :delEl="delEl"
            :newRole="newRole"
            :newData="newData"
            :subjectIdList="loadData.subjectIdList"
            :isEdit="isEdit"
          />
        </div>
      </div>
    </template>
  </common-page-edit>
</template>
<script>

import { API_METHODS , TYPE_ERRORS } from "../../../../constant";
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageEdit from '../../commonElement/CommonPageEdit.vue';
import EditLink from "./EditLinks";
import LinksSublinks from "./LinksSublinks.vue"
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import notify from '../../../../helpers/notify/notify'

export default {
  name: "EditPageUser",
  components: { CommonPageEdit, EditLink, LinksSublinks },
  data() {
    return {
      wait: false,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,

      routeNameShow: "UserShow",
      routeNameCreate: "CreateUser",

      pathBack: `/user`,
      modals: {
        history: false
      },
      apiNameGet: API_METHODS.GET_USER,
      apiNameCreate:API_METHODS.CREATE_USER,
      apiNameUpdate:API_METHODS.UPDATE_USER,
      loadData: null,
      newData: [],
      newRole: false,
      oldRole: null,
    };
  },
  computed: {
    paramGet() {
      return {
        id: this.$route.params.UserId || "0",
      }
    },
    isEdit() {
      if (this.$route.name == this.routeNameShow) {
        return false;
      }
      return true;
    },
   
    title() {
      let text1 = this.$t("Просмотр пользователя");
      let text2 = this.$t("Создание пользователя");
      let text3 = this.$t("Редактирование пользователя");

      let findShow = this.$route.path.indexOf('show')
      if(findShow !=-1) {
        return text1
      }
      let findCreate = this.$route.path.indexOf('create')
      if(findCreate !=-1) {
        return text2
      }
      let findEdit = this.$route.path.indexOf('edit')
      if(findEdit !=-1) {
        return text3
      }
      return text1
    },
   nameRoleEntity(){
         return NAME_OBJECT.Admin
   },
  },
  watch: {
    loadData: {
      deep: true,
      handler() {
        if (this.loadData.roleId !== this.oldRole) {
          this.$store.commit('CLEAR_DATA_SUBJECT_LINK'); // think later
          this.oldRole = this.loadData.roleId;
          this.loadData.subjectIdList = [];
          this.newRole = true;
          this.newData = [];
        }
      }
    }
  },
  methods:{
    convertLoadData(res){
      let loadData = res.object;
      let fieldInfoList = res.fieldInfoList;
      let scheme = {configForm: []} 

      this.oldRole = res.object.roleId;
      loadData = res.object;
      if (!("UserId" in this.$route.params)) {
        this.newRole = true;
      }
      scheme.configForm = convertFieldInfoListToScheme({
        fieldInfoList,
        hasLang: false
      });

      return {
        loadData, 
        fieldInfoList,
        scheme
      } 
      
    },
    setLoadData(loadData){
      this.loadData = loadData
    },
    paramsSave() {
      return {
          id: this.$route.params.UserId
        }
    },
    setLoadData(loadData){
      this.loadData = loadData
    },
    setSheme(scheme){
      this.scheme = scheme
    },
    setLoad(load) {
      this.load = load
    },
    setNewLink (el) {
      let findNewData = this.newData.find(_el => _el.entityId == el.entityId);
      if (!findNewData) {
        this.loadData.subjectIdList.push(el.entityId);
        this.newData.push(el);
      }
    },
    delEl(id) {
      let subjectIdList = clearReactive(this.loadData.subjectIdList);
      subjectIdList = subjectIdList.filter(el => el != id);
      this.newData = this.newData.filter(el => el.entityId != id);
      this.loadData.subjectIdList = subjectIdList;
      this.$store.commit('DEL_SUBJECT_LINK_EL', id);
    }
  }
};
</script>

