<template>
  <card :title="$t('Привязать')">
    <load-block :load="load"></load-block>
    <div class="row">
      <div class=" col-md-6">
        <label>{{$t('Объект')}}</label>
        <div class="form-group has-label">
          <div class="el-input">
            <v-select
              :label="labelSelect"
              v-model="selectCode"
              :options="optionShow"
              @search="onSearch"
              :disabled="!isEdit || !roleId"
            >
              <span slot="no-options">{{$t('Результатов не найдено')}}</span>
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-md-6 ">
        <label>&nbsp;</label>
        <l-button
          type="success"
          :disabled="!selectCode"
          wide
          block
          @click="addLink"
        >{{$t('Привязать')}}</l-button>
      </div>
    </div>
  </card>
</template>
<script>
import HttpClient from "../../../../util/HttpClient";
import { ApiService } from "../../../../util/ApiService";
import { API_METHODS } from "../../../../constant";
// import {COMPANY_CONNETCT_CODE} from '../store/type'
import LoadBlock from "../../../shared/LoadBlock.vue";
import clearReactive from "../../../../helpers/clearReactive";

export default {
  components: { LoadBlock },
  name: "EditCodeClient",
  props: {
    setNewLink: { type: Function },
    newRole: { type: Boolean, default: false },
    roleId: {},
    isEdit: { type: Boolean, default: true },
    subjectIdList: { type: Array }
  },
  data() {
    return {
      selectCode: null,
      option: [],
      labelSelect: "entityName",
      keyId: "entityId",
      load: false
    };
  },
  created() {
    this.initData();
  },
  watch: {
    roleId() {
      if (this.newRole) {
        this.option = [];
        this.selectCode = null;
        this.initData();
      }
    }
  },
  computed: {
    optionShow() {
      try {
        let option = this.option;
        let addList = this.addList;
        let filterIdList = this.subjectIdList;
        let complexList = [...option, ...addList];
        complexList = complexList.filter(el => {
          let find = filterIdList.find(id => id == el.entityId);
          if (!find) {
            return true;
          }
          return false;
        });
        return complexList;
      } catch (e) {
        console.error("ошибка в мерже данных");
        return [];
      }
    },
    addList() {
      let res = clearReactive(this.$store.state.users.dataLinkMem) || [];
      if (this.newRole) {
        return [];
      }
      return res;
    }
  },
  methods: {
    async initData() {
      this.load = true;
      try {
        const res = await this.loadData();
        this.load = false;
      } catch (e) {
        this.load = false;
      }
    },
    addLink() {
      this.setNewLink(this.selectCode);
      this.selectCode = null
    },
    onSearch(search, loading, event) {
      if (search.length < 3) return false;

      loading(true);
      this.search(loading, search, this);
    },
    search(loading, search, vm) {
      vm.loadData(search)
        .then(() => {
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    async loadData(search = null) {
      try {
        if (this.roleId == 0 || !this.roleId) {
          return [];
        }
        const params = {
          self: this,
          type: !this.newRole
            ? "unlinkedsubjectuser"
            : "unlinkedsubjectuserbyrole",
          query: {
            subListId: this.newRole ? this.roleId : this.$route.params.UserId,
            search: search,
            lang: this.$store.state.common.lang
          }
        };
        let res = await ApiService(API_METHODS.GET_USER_SUBLINKS, params);
        let responseData = res.object;
        if (!("map" in responseData)) {
          responseData = [responseData];
        }
        this.addOptions(responseData);
        return res;
      } catch (err) {
        return err;
      }
    },
    addOptions(result) {
      result.forEach(item => {
        let find = this.option.find(el => el[this.keyId] == item[this.keyId]);
        if (!find) {
          this.option.push(item);
        }
      });
    }
  }
};
</script>
